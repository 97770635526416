<template>
  <!-- modal add début-->

  <div class="modal-dialog modal-lg">
    <form
      @submit.prevent="confirm"
      class="modal-content border-0 bg-light text-dark"
    >
      <div class="modal-header mx-2">
        <h4 class="modal-title text-primary" id="modalLabelAjoutReference">
          Ajouter une nouvelle référence
        </h4>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <section class="row p-2">
          <div class="col-12 mb-3">
            <label class="form-label">Identification</label>
            <input
              type="text"
              class="form-control"
              autocomplete="off"
              required
              v-model="form.name_reference"
            />
          </div>
          <div class="col-12 mb-3">
            <label class="form-label">Lien</label>
            <input
              type="text"
              class="form-control"
              autocomplete="off"
              required
              v-model="form.url_reference"
            />
          </div>
          <div class="col-12 mb-3">
            <label class="form-label">Logo</label>
            <input
              type="file"
              accept="image/jpeg, image/png"
              name="img-article"
              class="form-control"
              id="img-article"
              required
              @change="changeFile"
            />
          </div>
          <div class="col-12 mb-3">
            <label class="form-label">Rang</label>
            <input
              type="number"
              class="form-control"
              autocomplete="off"
              required
              v-model="form.rang_reference"
            />
          </div>
        </section>
      </div>
      <div class="modal-footer mx-2">
        <button
          type="button"
          class="btn btn-secondary me-0"
          data-bs-dismiss="modal"
          ref="close"
        >
          Annuler
        </button>
        <button v-if="loading" class="btn btn-primary" type="button" disabled>
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
          ></span>
          Envoi...
        </button>
        <button v-else type="submit" class="btn btn-primary ms-3">
          Confirmer
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { createReference } from "../../api/reference";
import { success, error } from "../../utils/toast";

export default {
  name: "ModalAddReference",
  data() {
    return {
      form: {
        image: null,
        name_reference: "",
        //logo_partenaire: "",
        url_reference: "",
        rang_reference: 0,
      },
      loading: false,
    };
  },
  methods: {
    changeFile(event) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (e) => {
        const base64 = e.target.result;
        this.form.image = base64;
      };
    },
    confirm() {
      this.loading = true;
      createReference(this.form).then((result) => {
        if (result.data.error) {
          this.loading = false;
          error(result.data.error, "");
        } else {
          this.loading = false;
          this.$refs.close.click();
          success("Référence ajouté");
          this.$emit("fetch");
        }
      });
    },
  },
};
</script>
