import { BASE_URL } from "../configs";
import { axiosInstanceCollab } from "./config";

const URL = BASE_URL + "/reference";

export const createReference = (data) => {
  return axiosInstanceCollab.post(`${URL}`, data);
};

export const getReference = () => {
  return axiosInstanceCollab.get(`${URL}`);
};

export const getReferenceByRang = () => {
  return axiosInstanceCollab.get(`${BASE_URL}/referenceByRang`);
};

export const updateReference = (id, data) => {
  delete data.logo_reference;
  return axiosInstanceCollab.put(`${URL}/${id}`, data);
};

export const deleteReference = (id) => {
  return axiosInstanceCollab.delete(`${URL}/${id}`);
};

export const switchActiveReference = (switchData) => {
  const data = {
    isActive: !switchData.value,
  };
  return axiosInstanceCollab.put(
    `${URL}/switch-active/${switchData.id_reference}`,
    data
  );
};
