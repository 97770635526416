<template>
  <section class="bg-light p-3 h-100">
    <div class="d-flex justify-content-between">
      <h2>Reference</h2>
      <button
        class="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#modalAdd"
      >
        Ajouter
      </button>
    </div>
    <hr />
    <article class="mt-3">
      <div
        class="d-flex flex-wrap align-items-stretch justify-content-strat row-cols-4"
      >
        <div
          class="col cursor-pointer p-3"
          v-for="(item, i) in references"
          :key="i"
          @click="chooseUpdate(item)"
          :class="{
            'border border-primary border-2':
              item.id_reference == form.id_reference,
          }"
        >
          <div class="card shadow bg-dark text-light border-0 h-100">
            <div class="img-card">
              <img
                :src="URL_IMAGE + item.logo_reference"
                width="200"
                alt="..."
              />
            </div>
            <div class="card-body">
              <h5 class="card-title text-primary">
                {{ item.name_reference }}
              </h5>
              <p class="card-text">
                {{ item.url_reference }}
              </p>
              <p class="card-text d-flex">rang: {{ item.rang_reference }}</p>
              <div
                class="form-check form-switch"
                data-bs-toggle="modal"
                data-bs-target="#switchActive"
              >
                <input
                  @click="updateIsActive(item.isActive, item.id_reference)"
                  class="form-check-input cursor-pointer"
                  type="checkbox"
                  :checked="item.isActive"
                  :value="item.isActive"
                />
              </div>
              <div class="col-12 mb-2 mt-4 d-flex justify-content-between">
                <button
                  class="btn btn-outline-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#modalUpdate"
                  @click="initUpdate(item)"
                >
                  Modifier
                </button>
              </div>
              <div class="col-12 mb-2 mt-4 d-flex justify-content-between">
                <button
                  class="btn btn-outline-danger"
                  data-bs-toggle="modal"
                  data-bs-target="#modalDelete"
                  @click="initDelete(item.id_reference)"
                >
                  Supprimer
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>

    <!-- modal add début-->
    <div
      class="modal fade"
      data-bs-backdrop="static"
      id="modalAdd"
      tabindex="-1"
      aria-labelledby="modalLabelAdd"
      aria-hidden="true"
    >
      <ModalAddReference @fetch="fetch" />
    </div>
    <!-- modal add fin-->

    <!-- modal update début-->
    <div
      class="modal fade"
      data-bs-backdrop="static"
      id="modalUpdate"
      tabindex="-1"
      aria-labelledby="modalLabelAdd"
      aria-hidden="true"
    >
      <ModalUpdateReference :dataUpdate="dataUpdate" @fetch="fetch" />
    </div>
    <!-- modal update fin-->

    <!-- modal delete début-->
    <div
      class="modal fade"
      id="modalDelete"
      tabindex="-1"
      aria-labelledby="modalLabelDelete"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content border-0 bg-light text-dark">
          <div class="modal-header mx-2">
            <h4 class="modal-title text-primary" id="modalLabelDelete">
              Êtes-vous sûr ?
            </h4>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <section class="row p-2">
              <div class="col-12">Supprimer la référence</div>
            </section>
          </div>
          <div class="modal-footer mx-2">
            <button
              type="button"
              class="btn btn-secondary me-0"
              data-bs-dismiss="modal"
              ref="CloseDelete"
            >
              Annuler
            </button>
            <button
              v-if="loading"
              class="btn btn-primary"
              type="button"
              disabled
            >
              <span
                class="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Envoi...
            </button>
            <button
              v-else
              @click="deleteReference()"
              class="btn btn-primary ms-3"
            >
              Supprimer
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- modal delete fin-->
  </section>
</template>

<script>
import {
  getReference,
  deleteReference,
  switchActiveReference,
} from "../../api/reference";
import { URL_IMAGE } from "../../configs/index";
import { success } from "../../utils/toast";
import ModalAddReference from "../../components/admin/ModalAddReference.vue";
import ModalUpdateReference from "../../components/admin/ModalUpdateReference.vue";

export default {
  name: "ReferenceListe",
  components: { ModalAddReference, ModalUpdateReference },
  data() {
    return {
      loading: false,
      URL_IMAGE: URL_IMAGE,
      references: [],
      form: {
        image: null,
        name_reference: "",
        id_reference: 0,
        //logo_partenaire: "",
        url_reference: "",
        rang_reference: 0,
      },
      dataUpdate: {},
      idDelete: 0,
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    updateIsActive(value, id) {
      switchActiveReference({ value: value, id_reference: id }).then(
        (result) => {
          success(
            `La référence est maintenant ${
              result.data.isActive ? "activé" : "désactivé"
            }`
          );
          this.fetch();
        }
      );
    },
    fetch() {
      getReference().then((result) => {
        this.references = result.data;
      });
    },
    initUpdate(data) {
      this.dataUpdate = data;
    },
    chooseUpdate(data) {
      this.form = {
        image: null,
        id_reference: data.id_reference,
        name_reference: data.name_reference,
        //logo_partenaire: "",
        url_reference: data.url_reference,
        rang_reference: data.rang_reference,
      };
    },
    changeFile(event) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (e) => {
        const base64 = e.target.result;
        this.form.image = base64;
      };
    },
    initDelete(id) {
      this.idDelete = id;
    },
    deleteReference() {
      deleteReference(this.idDelete).then(() => {
        this.fetch();
        this.$refs.CloseDelete.click();
        success("Réference supprimé");
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.img-card {
  margin: 20px auto;
}
</style>
